<template>
  <v-container fluid class="pa-0">
    <back-to-store :title="$t('order.orderSummary')"></back-to-store>
    <v-container class="pa-16 min-height">
      <v-row class="ma-0">
        <p class="p josefin-light ma-0">
          {{ $t("order.orderNo") }}: <b>{{ getOrder.id }}</b>
        </p>
      </v-row>
      <v-row class="ma-0">
        <p class="p josefin-light ma-0">
          {{ $t("order.createdAt") }}:
          <b>{{ moment(getOrder.created_at).format("YYYY-MM-DD") }}</b>
        </p>
      </v-row>
      <v-row class="ma-0">
        <p class="p josefin-light ma-0">
          {{ $t("order.customer") }}:
          <b>{{
            getOrder.customer_first_name + " " + getOrder.customer_last_name
          }}</b>
        </p>
      </v-row>
      <v-row class="ma-0">
        <p class="p josefin-light ma-0">
          {{ $t("profile.email") }}: <b>{{ getOrder.customer_email }}</b>
        </p>
      </v-row>
      <v-row class="ma-0">
        <p class="p josefin-light ma-0">
          {{ $t("order.shippingAddress") }}:
          <b>{{
            getOrder.shipping_address.address1[0] +
            ", " +
            getOrder.shipping_address.city +
            ", " +
            getOrder.shipping_address.state +
            ", " +
            getOrder.shipping_address.country
          }}</b>
        </p>
      </v-row>
      <v-row class="ma-0">
        <p class="p josefin-light ma-0">
          {{ $t("order.status") }}: <b>{{ getOrder.status }}</b>
        </p>
      </v-row>
      <v-row class="ma-0 mt-3">
        <v-btn
          class="btn_second"
          v-if="getOrder.status === 'pending_payment'"
          @click="payCurrentOrder()"
          width="268"
        >
          {{ $t('order.payOrder') }}
        </v-btn>
      </v-row>
      <v-row class="mx-0 mt-16 d-none d-lg-flex">
        <v-simple-table style="min-width: 100% !important">
          <template v-slot:default>
            <thead class="josefin-light font-weight-light">
              <tr class="dark">
                <th class="text-center p gold">
                  {{ $t("cart.quantity") }}
                </th>
                <th class="text-left p gold">
                  {{ $t("cart.item") }}
                </th>
                <th class="text-center p gold">
                  {{ $t("order.unitPrice") }}
                </th>
                <th class="text-center p gold">
                  {{ $t("cart.total") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getOrder.items" :key="index">
                <td class="font-weight-regular text-center josefin-light">
                  {{ item.qty_ordered }}
                </td>
                <td class="josefin-light">
                  <span class="copperplate-medium"> {{ item.name }}</span>
                </td>
                <td class="font-weight-regular text-center josefin-light">
                  RD${{ Intl.NumberFormat().format(item.price) + ".00" }}
                </td>
                <td class="font-weight-bold text-center josefin-light">
                  RD${{ Intl.NumberFormat().format(item.total) + ".00" }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
      <v-row
        class="d-lg-none py-3"
        v-for="(item, index) in getOrder.items"
        :key="index"
      >
        <v-col cols="12">
          <span class="copperplate gold text-center" v-html="item.name"></span>
        </v-col>
        <v-col cols="12" class="mt-3">
          <v-row class="my-n5">
            <v-col
              ><p class="josefin-light font-weight-bold">
                {{ $t("cart.price") }}
              </p></v-col
            >
            <v-col class="justify-end d-flex">
              <p class="josefin-light">
                RD${{ Intl.NumberFormat().format(item.price) + ".00" }}
              </p></v-col
            >
          </v-row>
          <v-row class="my-n5">
            <v-col
              ><p class="josefin-light font-weight-bold">
                {{ $t("cart.quantity") }}
              </p></v-col
            >
            <v-col class="justify-end d-flex">
              <p class="josefin-light">{{ item.qty_ordered }}</p>
            </v-col>
          </v-row>
          <v-row class="my-n5">
            <v-col
              ><p class="josefin-light font-weight-bold">
                {{ $t("cart.total") }}
              </p></v-col
            >
            <v-col class="justify-end d-flex">
              <p class="josefin-light">
                RD${{ Intl.NumberFormat().format(item.total) + ".00" }}
              </p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="end" class="mx-0 mt-10">
        <h3 class="font-weight-bold copperplate ma-0 pr-4">
          ENVÍO: RD${{
            Intl.NumberFormat().format(getOrder.shipping_amount) + ".00"
          }}
        </h3>
      </v-row>
      <v-row justify="end" class="mx-0 mt-10">
        <h2 class="font-weight-bold copperplate ma-0 pr-4">
          TOTAL: RD${{
            Intl.NumberFormat().format(getOrder.base_grand_total) + ".00"
          }}
        </h2>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import backToStore from "@/components/home/back-to-store.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "orderDetail",
  components: {
    backToStore,
  },
  async created() {
    await this.fetchOrderById(this.$route.params.order_id);
  },
  computed: {
    ...mapGetters("orders", ["getOrder"]),
  },
  methods: {
    ...mapActions("orders", ["fetchOrderById"]),
    ...mapActions("shoppingCart", ["payOrder"]),
    async payCurrentOrder() {
      const data = await this.payOrder(this.getOrder.id);

      window.location.href = data.redirect_url;
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr:hover {
  background: transparent !important;
}
tr:not(:last-child) td {
  border: 0 !important;
}
tr:last-child td {
  border-bottom: 1px solid !important;
}
</style>
